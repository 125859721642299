import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';

import './form.scss';

const MAIL_URL = `https://admin.bigcityhomeservice.com/wp-json/contact-form-7/v1/contact-forms/5/feedback`;

const BookingForm = ({ preSelect, services, title }) => {
  const [service, setService] = useState();
  const [subject, setSubject] = useState();
  const [city, setCity] = useState();

  const navigate = useNavigate();

  const initCCConversion = (val) => {
    window.ccConVal = val || 99;
    var script = document.createElement('script');
    var target = 'https://www.clickcease.com/monitor/cccontrack.js';
    var elem = document.head;
    script.type = 'text/javascript';
    script.src = target;
    elem.appendChild(script);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    data.append('mail_subject', subject);
    data.append('city', city);
    data.append('_wpcf7_unit_tag', true);

    try {
      const response = await axios.post(MAIL_URL, data);

      if (response.status === 200) {
        toast.success(response.data.message);
        event.target.reset();
        initCCConversion();
        navigate('/thank-you');
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please, try again!');
      console.log(error);
    }
  };

  useEffect(() => {
    switch (preSelect) {
      case 'dishwasher-repair':
        setService('Dishwasher Repair');
        break;
      case 'dryer-repair':
        setService('Dryer Repair');
        break;
      case 'freezer-repair':
        setService('Freezer Repair');
        break;
      case 'microwave-repair':
        setService('Microwave Repair');
        break;
      case 'oven-repair':
        setService('Oven Repair');
        break;
      case 'range-repair':
        setService('Range Repair');
        break;
      case 'refrigerator-repair':
        setService('Refrigerator Repair');
        break;
      case 'washer-repair':
        setService('Washer Repair');
        break;
      default:
        setService('');
        break;
    }
  }, [preSelect]);

  useEffect(() => {
    const city = window.location.href.split('/')[2].split('.')[0];

    switch (city) {
      default:
        setSubject('HVAC Request from San Diego');
        setCity('sandiego');
        break;
    }
  }, []);

  return (
    <div className="booking-form">
      <h4>{title}</h4>
      <form onSubmit={onSubmit}>
        <input required type="text" placeholder="First Name" name="firstName" />
        <input required type="text" placeholder="Last Name" name="lastName" />
        <input required type="email" placeholder="Email Address" name="email" />
        <InputMask mask="(999) 999 9999" maskChar="" name="phoneNumber" placeholder="Phone Number" />
        <select
          required
          name="service"
          value={service}
          defaultValue={service}
          onChange={(event) => setService(event.target.value)}
        >
          <option hidden value="">
            Please choose an option
          </option>
          <option value="Air Conditioning">Air Conditioning</option>
          <option value="Heating">Heating</option>
          <option value="Indoor Air Quality">Indoor Air Quality</option>
        </select>
        <textarea required name="message" placeholder="Please let us know if you have any special requests" />
        <input type="submit" value="Submit" className="button" />
      </form>

      <div className="data-protection-label">
        <svg aria-hidden="true" viewBox="0 0 24 24">
          <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
        </svg>

        <p>Data protected and privacy</p>
      </div>
    </div>
  );
};

export default BookingForm;
