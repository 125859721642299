import { useEffect, useState } from 'react';

import './whyWe.scss';

import { BookingForm } from '@components';

const WhyWe = ({ title, data, services }) => {
  const [preSelect, setPreSelect] = useState();
  const [location, setLocation] = useState();

  useEffect(() => {
    const location = window.location.pathname.split('/')[1];
    const city = window.location.href.split('/')[2].split('.')[0];

    setPreSelect(location);

    switch (city) {
      default:
        setLocation('San Diego');
        break;
    }
  }, [title]);

  return (
    <section className="why-we">
      <div className="container">
        <div>
          <h2 className="section-title">Why Big City HVAC?</h2>

          <p>We are a leading HVAC company in {location} and surrounding areas.</p>

          <div className="why-we__reasons">
            {data?.map((el, index) => (
              <div className="why-we__reasons--card" key={index}>
                <div className="header">
                  <div className="icon">
                    <img src={el.icon} alt={el.title} />
                  </div>

                  <h4>
                    {el.title.split(' ')[0]}
                    <br />
                    {el.title.split(' ')[1]}
                  </h4>
                </div>

                <p>{el.description}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="why-we__form">
          <BookingForm preSelect={preSelect} services={services} title="Tell us how we can help you today" />
        </div>
      </div>
    </section>
  );
};

export default WhyWe;
