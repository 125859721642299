import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Layout } from '@components';

import { Form } from '@sections';

const BookNow = ({ email, phones, logos, services }) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const location = useLocation();

  useEffect(() => {
    const city = window.location.href.split('/')[2].split('.')[0];

    switch (city) {
      default:
        setPhoneNumber(phones.hvac);
        break;
    }
  }, []);

  return (
    <Layout phone={phoneNumber} email={email} logos={logos} title="Book Now">
      <Form preSelect={location.state?.prevLocation} services={services} />
    </Layout>
  );
};

export default BookNow;
