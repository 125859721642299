import { NavLink } from 'react-router-dom';

import './footer.scss';

const Footer = ({ logo, phone, email }) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="page-footer">
      <div className="container">
        <NavLink to="/" className="page-footer__logo">
          <img src={logo} alt="Big City Appliance Repair" />
        </NavLink>

        <a className="phone" href={`tel:${phone}`}>
          {phone}
        </a>

        {/* <a className="email" href={`mailto:${email}`}>
          {email}
        </a> */}

        {window.location.href.split('/')[2].split('.')[0] === 'sandiego' && (
          <p className="address">3113 Market St Unit A, San Diego, CA 92102</p>
        )}

        <div className="page-footer__underline">
          <div className="legal-links">
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>
            <NavLink to="/terms-of-use">Terms Of Use</NavLink>
            <NavLink to="/accessibility-statement">Accessibility Statement</NavLink>
          </div>

          <p>©{currentYear} Big City. Heating, Air conditioning &amp; Appliance Repair. All Rights Reserved.</p>

          <a href="http://toporin.com/" target="_blank" rel="noreferrer">
            Designed by <strong>Toporin Studio®</strong> in California
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
