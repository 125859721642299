import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useFetch from 'react-fetch-hook';

import { Spinner } from '@components';

import PageTemplate from '@pages/PageTemplate';
import LegalPage from '@pages/LegalPage';
import BookNow from '@pages/BookNow';
import ThankYou from '@pages/ThankYou';

export const ADMIN_URL = 'https://admin.bigcityhomeservice.com/wp-json/wp/v2';

const App = () => {
  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/pages`);

  const settings = useFetch(`${ADMIN_URL}/general_settings?slug=settings-sd`);

  useEffect(() => {
    if (error || settings.error) toast.error('Data loading error. Please reload the page!');
  }, [error, settings.error]);

  if (isLoading || settings.isLoading) return <Spinner />;

  console.log(settings);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PageTemplate
            // seo={page.acf.seo}
            title={'HVAC'}
            phones={{
              hvac: settings.data[0].acf.phone_hvac,
            }}
            email={settings.data[0].acf.email}
            logos={{
              header: settings.data[0].acf.header_logo,
              footer: settings.data[0].acf.footer_logo,
              badges: settings.data[0].acf.badges,
            }}
            why_we={{
              hvac: settings.data[0].acf.why_we_content.hvac,
            }}
            reviews={settings.data[0].acf.city_reviews}
          />
        }
      />

      <Route
        path="/book-now"
        element={
          <BookNow
            phones={{
              hvac: settings.data[0].acf.phone_hvac,
            }}
            email={settings.data[0].acf.email}
            logos={{
              header: settings.data[0].acf.header_logo,
              footer: settings.data[0].acf.footer_logo,
            }}
          />
        }
      />

      <Route
        path="/privacy-policy"
        element={
          <LegalPage
            title="Privacy Policy"
            data={settings.data[0].acf.privacy_policy}
            phones={{
              hvac: settings.data[0].acf.phone_hvac,
            }}
            email={settings.data[0].acf.email}
            logos={{
              header: settings.data[0].acf.header_logo,
              footer: settings.data[0].acf.footer_logo,
            }}
          />
        }
      />

      <Route
        path="/accessibility-statement"
        element={
          <LegalPage
            title="Accessibility Statement"
            data={settings.data[0].acf.accessibility_statement}
            phones={{
              hvac: settings.data[0].acf.phone_hvac,
            }}
            email={settings.data[0].acf.email}
            logos={{
              header: settings.data[0].acf.header_logo,
              footer: settings.data[0].acf.footer_logo,
            }}
          />
        }
      />
      <Route
        path="/terms-of-use"
        element={
          <LegalPage
            title="Terms Of Use"
            data={settings.data[0].acf.terms_of_use}
            phones={{
              hvac: settings.data[0].acf.phone_hvac,
            }}
            email={settings.data[0].acf.email}
            logos={{
              header: settings.data[0].acf.header_logo,
              footer: settings.data[0].acf.footer_logo,
            }}
          />
        }
      />

      <Route
        path="/thank-you"
        element={
          <ThankYou
            title="Thank You Page"
            seo={settings.data[0].acf.ga}
            phones={{
              seattle: settings.data[0].acf.phone_seattle,
            }}
            email={settings.data[0].acf.email}
            logos={{
              header: settings.data[0].acf.header_logo,
              footer: settings.data[0].acf.footer_logo,
            }}
          />
        }
      />

      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};

export default App;
