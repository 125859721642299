import './about.scss';

const About = ({ title }) => (
  <section className="about">
    <div className="container">
      <div className="about__content">
        <h2 className="section-title">Certified {title} Technicians</h2>

        <div>
          <p>Big City is a reliable company that can help you with all of your HVAC needs.</p>
          <p>We provide transparent pricing with no hidden fees, and all repairs come with a warranty. </p>
          <p>Our team of expert technicians will quickly identify and fix any issues with your HVAC on-site.</p>
          <p>
            We strive to improve your quality of life by ensuring your HVAC are working at their best. If you need an
            HVAC repair, be sure to contact us to schedule an appointment.
          </p>
        </div>
      </div>

      <div
        className="about__image"
        style={{
          backgroundImage: `url(https://admin.bigcityhomeservice.com/wp-content/uploads/2023/06/BigCityHvacApplianceRepair-28-copy.webp)`,
        }}
      />
    </div>
  </section>
);

export default About;
