import { useState, useEffect } from 'react';
import { Layout, Seo } from '@components';

const ThankYou = ({ title, phones, email, logos, seo }) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [gaCode, setGaCode] = useState();

  useEffect(() => {
    const city = window.location.href.split('/')[2].split('.')[0];

    switch (city) {
      default:
        setPhoneNumber(phones.hvac);
        setGaCode(seo.hvac);
        break;
    }
  }, [title]);

  return (
    <Layout phone={phoneNumber} email={email} logos={logos} title={title}>
      <Seo data={{ title: 'Thank You Page' }} code={gaCode} />

      <section className="thank-you">
        <div className="title">Thank you!</div>
        <div className="subtitle">We’ll respond to your request shortly!</div>

        <p>
          Big City has the best customers! We truly appreciate your business, and we’re so grateful for the trust you’ve
          placed in us. We look forward to serving you!
        </p>
      </section>
    </Layout>
  );
};

export default ThankYou;
