import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import { Header, Footer } from '@components';

import './layout.scss';

const Layout = ({ pageId, phone, email, title, logos, children }) => {
  const [isVisible, setVisibility] = useState(false);

  const handleScroll = () => {
    setVisibility(window.scrollY > 250);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div id={pageId}>
      <Header phone={phone} logo={logos.header} />
      <main>{children}</main>
      <Footer phone={phone} email={email} logo={logos.footer} />
      <ToastContainer />
      <div className={`mobile-cta-buttons ${isVisible ? 'on' : 'off'}`}>
        <a href={`tel:${phone}`}>{phone}</a>
        <Link to="/book-now">Book Now</Link>
      </div>
    </div>
  );
};

export default Layout;
