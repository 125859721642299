import { useState, useEffect } from 'react';

import { Layout } from '@components';

import { ContentBlock, CallToAction } from '@sections';

const LegalPage = ({ title, data, phones, email, logos }) => {
  const [phoneNumber, setPhoneNumber] = useState();

  useEffect(() => {
    const city = window.location.href.split('/')[2].split('.')[0];

    switch (city) {
      default:
        setPhoneNumber(phones.hvac);
        break;
    }
  }, []);

  return (
    <Layout phone={phoneNumber} email={email} logos={logos} title={title}>
      <ContentBlock content={data} />
      <CallToAction phone={phoneNumber} />
    </Layout>
  );
};

export default LegalPage;
