import { useState, useEffect, lazy } from 'react';
import { Layout, Seo } from '@components';

import { Hero } from '@sections';

const WhyWeLazy = lazy(() => import('../sections/WhyWe/WhyWe'));
const SatisfactionLazy = lazy(() => import('../sections/Satisfaction/Satisfaction'));
const AboutLazy = lazy(() => import('../sections/About/About'));
const ReviewsLazy = lazy(() => import('../sections/Reviews/Reviews'));
const CallToActionLazy = lazy(() => import('../sections/CallToAction/CallToAction'));

const PageTemplate = ({ title, about, phones, email, logos, why_we, reviews, seo }) => {
  const [phoneNumber, setPhoneNumber] = useState();
  // const [content, setContent] = useState();
  const [reviewsList, setReviewsList] = useState();
  const [seoData, setSeoData] = useState();
  const [whyWe, setWhyWe] = useState();

  useEffect(() => {
    const city = window.location.href.split('/')[2].split('.')[0];

    switch (city) {
      default:
        setPhoneNumber(phones.hvac);
        setReviewsList(reviews.hvac);
        // setSeoData(seo.hvac);
        setWhyWe(why_we.hvac);

        break;
    }
  }, []);

  return (
    <Layout phone={phoneNumber} email={email} logos={logos} title={title}>
      {/* <Seo data={seoData} /> */}
      <Hero title={title} phone={phoneNumber} badges={logos.badges} />
      <WhyWeLazy title={title} data={whyWe} />
      <SatisfactionLazy />
      <AboutLazy title={title} />
      <ReviewsLazy reviews={reviewsList} />
      <CallToActionLazy phone={phoneNumber} />
    </Layout>
  );
};

export default PageTemplate;
